@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.login-container {
  display: flex;
  width: 100%;
  height: fit-content;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .username-box,
  .password-box {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      color: var(--primary-text-color);
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      // font-style: normal;
      font-weight: 100;
      line-height: 16px;
      letter-spacing: 0.15px;
    }
  }
}

@media (max-width: 768px) {
  .login-form {
    width: 100%;
  }
}

.input-username-field,
.input-password-field {
  color: var(--General-Stroke);

  .MuiInputBase-root {
    background-color: transparent !important;
    border-radius: 8px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--General-Stroke) !important;

    &:hover,
    &:focus-within,
    &:focus-within {
      border-color: var(--General-Stroke) !important;
    }
  }

  .MuiInputLabel-root {
    border-color: var(--General-Stroke);
  }

  .MuiInputLabel-root.Mui-focused {
    border-color: var(--General-Stroke);
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    color: var(--primary-text-color);
    border-color: var(--General-Stroke);
    background-color: transparent;
  }
}

.remember-me-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
