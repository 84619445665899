.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  background-color: transparent;

  &__logo {
    display: flex;
    align-items: center;

    img {
      max-width: 60%;
      height: auto;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .header__language-select {
      font-size: 16px;
      background-color: var(--General-Stroke);
    }
  }
}

@media (max-width: 1024px) {
  /* Tablet */
  .header {
    align-items: center;

    &__actions {
      .header__language-select {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  /* Mobile - iPhone */
  .header {
    &__logo img {
      max-width: 50%;
    }

    &__actions {
      align-items: center;

      .header__language-select {
        font-size: 12px;
      }
    }
  }
}
