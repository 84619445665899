.gradient-text {
    background: linear-gradient(114deg, #C6D92E 15.44%, #76D300 50%, #2BD58D 84.56%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; /* For non-WebKit browsers */
}

.form-container-custom {
    border-radius: 32px;
    border: 0.5px solid var(--General-Stroke);
    background-color: var(--background-color-chat-container);
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
    background-color: var(--background-color-chat-container);
    border-radius: 32px;
    border: 0.5px solid var(--General-Stroke-2);
    box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 80px 0px rgba(255, 255, 255, 0.1);
}
.custom-dialog .MuiPaper-root {
    background-color: transparent; /* Ensure no background color is applied */
    border-radius: 32px;
}
// .custom-dialog .MuiDialog-paper {
//     border-radius: 16px; /* Adjust border-radius as needed */
//     border: 2px solid red;
// }

@media (max-width: 768px) {
}