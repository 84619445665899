/* Base styles for background */
.app-background {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  background-image: var(--background-image);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (min-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/* Default styles (light theme) */
:root {
  --button-choose-price: #171e24;
  --background-notice-limit: #FFFFFF;
  --background-color: #FAFAFB;
  /* Default background color */
  --background-color-chat-container: #ffffff;
  // --background-image: linear-gradient(
  //   159deg,
  //   rgba(255, 218, 99, 0.70) 0%, /* Adjusted color */
  //   rgba(225, 255, 5, 0.70) 6%,  /* Adjusted color */
  //   rgba(166, 255, 0, 0.70) 5%  /* Adjusted color */
  // ); /* Light theme gradient */
  --theme-light-image: url('../assets/images/success_image_light.jpg');
  --theme-dark-image: url('../assets/images/success_image.jpg');

  --text-color: #000000;
  /* Light text color */
  --primary-text-color: #000000;
  --primary-text-color-hover: #007bff;
  --color-dark-form-background: #ffffff;
  --General-Stroke: #d1d1d1;
  --border-right-left-bar: #E8EAEB;
  --General-surface-primary-2: #f0f0f0;
  --button-text-color: #000000;
  --Color-Style-Primary-Gradient-Primary: linear-gradient(114deg,
      #c6d92e 15.44%,
      #76d300 50%,
      #2bd58d 84.56%);
  --Color-Style-Primary-Gradient-Primary-hover: linear-gradient(114deg,
      #2bd58d 15.44%,
      #76d300 50%,
      #c6d92e 84.56%);
  --Color-Grey-grey-200: #E8EAEB;
  --Button-button-label-primary: #171e24;
  --Button-button-background-primary: #8ad300;
  --Button-button-background-secondary: #F0F1F2;
  --Text-text-placeholder: #919496;
  --transition-duration: 0.3s;
  --transition-timing-function: ease-in-out;
  --text-history-chat: #F0F1F2;
}

[data-theme='light'] {
  --theme-image: var(--theme-light-image);
}

/* Dark mode styles */
[data-theme='dark'] {
  --button-choose-price: #2d2d2d;
  --background-notice-limit: #B5B7B8;
  --border-right-left-bar: #171E24;
  --General-surface-primary-2: #0B1013;
  --background-color-chat-container: #090C0F;
  --theme-image: var(--theme-dark-image);
  // --background-color: #171e24; /* Dark background color */
  --background-color: var(--General-surface-primary-2);
  --background-image: url('../assets/images/layer-background-darkmode.png') no-repeat; /* Dark mode image */
  --text-color: #f0f0f0;
  /* Light text color for dark theme */
  --primary-text-color: #f0f0f0;
  --primary-text-color-hover: #76d300;
  --color-dark-form-background: #171e24;
  --General-Stroke: #2d2d2d;
  --Color-Grey-grey-200: #171E24;
  --button-text-color: #f0f0f0;
  --Color-Style-Primary-Gradient-Primary: linear-gradient(114deg,
      #2bd58d 15.44%,
      #76d300 50%,
      #c6d92e 84.56%);
  --Color-Style-Primary-Gradient-Primary-hover: linear-gradient(114deg,
      #c6d92e 15.44%,
      #76d300 50%,
      #2bd58d 84.56%);
  --Button-button-label-primary: #f0f0f0;
  --Button-button-background-primary: #8ad300;
  --Button-button-background-secondary: #171e24;
  --Text-text-placeholder: #919496;
  --text-history-chat: #10161B;
}

/* Apply theme-specific styles */
body {
  background-color: var(--background-color);
  background-image: var(--background-image);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  /* Apply gradient background or image */
  color: var(--text-color);
  transition: background-color var(--transition-duration) var(--transition-timing-function),
    background-image var(--transition-duration) var(--transition-timing-function),
    color var(--transition-duration) var(--transition-timing-function);
}

/* Add any other necessary styles here */