.form-container {
  display: flex;
  width: 35vw;
  padding: 40px 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 32px;
  border: 0.5px solid var(--General-Stroke-2);
  background: var(--color-dark-form-background);
  box-shadow: 0px 0px 40px 0px rgba(255, 255, 255, 0.25) inset,
    0px 0px 80px 0px rgba(255, 255, 255, 0.1);

}

@media (max-width: 768px) {
  .form-container {
    width: 100vw;
    border-radius: 0px;
    padding: 0px;
    box-shadow: none;
    background-color: transparent;
  }
}