.separator {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--General-Stroke);
  }

  .text {
    padding: 0 10px;
    color: var(--General-Stroke);
    font-size: 14px;
    font-weight: bold;
  }
}

.theme-toggle {
  padding: 5px 10px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}
