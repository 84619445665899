.register-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .register-container {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .register-container {
    padding: 1rem;
  }
  
  .register-title {
    font-size: 1.5rem;
  }
  
  .register-button {
    font-size: 0.875rem;
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .register-container {
    padding: 0.75rem;
  }
  
  .register-title {
    font-size: 1.25rem;
  }
  
  .register-button {
    font-size: 0.75rem;
    padding: 0.5rem;
  }
  
  .register-link {
    font-size: 0.875rem;
  }
}
